<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="ingredientCatMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
              <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group
              :label="$t('IngredientCategoryMaster.Ingredient Category Name')"
            >
              <validation-provider
                #default="{ errors }"
                name="Ingredient Category Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.ingcategory_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="
                    $t('IngredientCategoryMaster.Ingredient Category Name')
                  "
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('IngredientCategoryMaster.Ingredient Category Code')"
            >
              <validation-provider
                #default="{ errors }"
                name="Ingredient Category Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.ingcategory_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="
                    $t('IngredientCategoryMaster.Ingredient Category Code')
                  "
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('IngredientCategoryMaster.Description')">
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules=""
              >
                <b-form-textarea
                  v-model="form.ingcategory_desc"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('IngredientCategoryMaster.Description')"
                  :disabled="!$can('write', 'masters')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveIngredientCatMaster"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import FoodServices from "@/apiServices/FoodServices";

export default {
  name: "IngredientCategoryForm",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      ingcategory_id: null,
      form: {
        ingcategory_name: "",
        ingcategory_code: "",
        ingcategory_desc: "",
        ingcategory_is_active: true,
      },
      required,
    };
  },

  methods: {
    saveIngredientCatMaster() {
      this.$refs.ingredientCatMasterValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              // this.form.ingcategory_id = this.ingcategory_id;
              // console.log("ing category=>",this.form);return false;
              const ingredientCategoryMaster = await FoodServices.saveIngredientCatMaster(
                this.form
              );

              if (ingredientCategoryMaster.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      ingredientCategoryMaster.data.message ||
                      "Ingredient Category Added Successfull",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.$router.replace("/food/ingredient-cat-list");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      ingredientCategoryMaster.data.message ||
                      "Cannot Added Ingredient Category",
                    icon: "EditIcon",
                    variant: "failure",
                  },
                });
              }
            } catch (error) {
              console.log("Error in saveIngredientCatMaster ", error);
              console.log({ error });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || "Error in saveIngredientCatMaster",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          }
        });
    },
    async getSingleIngredientCatMaster(ingcategory_id) {
      try {
        const response = await FoodServices.getSingleIngredientCatMaster(
          ingcategory_id
        );
        let singleIngredient = response.data.data;
        if (response.data.status) {
          this.form = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting getSingleIngredientCatMaster ", err);
      }
    },
  },
  beforeMount() {
    if (
      this.$route.params.ingcategory_id &&
      !isNaN(this.$route.params.ingcategory_id)
    ) {
      this.ingcategory_id = this.$route.params.ingcategory_id;
      this.getSingleIngredientCatMaster(this.ingcategory_id);
    }
  },
};
</script>

<style></style>
